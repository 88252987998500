import React, { useContext, useEffect, useRef } from 'react';
import { AppContext } from '@/store/context';
import { CaseStudyData, CaseStudyProps } from '@/mock/SiteData';
import { LeftRight } from '@/components/shared/left-right';
import { CopyCentered } from '@/components/shared/copy-centered';
import { Separator } from '@/components/ui/separator';
import { TechnologiesList } from '@/components/shared/technologies-list';
import { TitleCopy } from '@/components/shared/title-copy';
import { Review } from '@/components/shared/review';
import { ImageGallery } from '@/components/shared/image-gallery';
import SEO from '@/components/shared/SearchEngineOptimizations/SearchEngineOptimizations';
import useTriggerLightTheme from '@/hooks/useTriggerLightTheme';
import NotFoundPage from '@/pages/404';
import ReactLogo from '@/assets/images/technologies/react.inline.svg';
import SpringLogo from '@/assets/images/technologies/spring.inline.svg';
import TypescriptLogo from '@/assets/images/technologies/typescript.inline.svg';
import ClutchLogo from '@/assets/images/clutch.inline.svg';
import useScrollAnimation from '@/hooks/useScrollAnimation';
import useStickyScrollAnimation from '@/hooks/useStickyScrollAnimation';
import { LeftRightCopy } from '@/components/shared/left-right-copy';
import { cn } from '@/lib/utils';
import { SectionHeader } from '@/components/shared/section-header';
import { CaseStudyBanner } from '@/components/shared/case-study-banner';
import { FullScreenSlider } from '@/components/shared/full-screen-slider';
import { AnimatedTagCopy } from '@/components/shared/animated-tag-copy';
import { useTransitionAnimation } from '@/hooks/useTransitionAnimation';

const ANIMATED_CLASSNAME = 'animated fadeInUp';

const caseStudy: CaseStudyProps | undefined = CaseStudyData.find((m) => m.id === 'unifine');

const Unifine = () => {
  const { setCurrentPage } = useContext(AppContext);

  const { tlState, defaultAnimationOffset } = useTransitionAnimation();

  const projectWhiteSectionRef = useRef<HTMLDivElement>(null);

  useTriggerLightTheme(
    projectWhiteSectionRef.current,
    defaultAnimationOffset,
    defaultAnimationOffset,
    tlState,
    0.5,
  );

  // TODO: we'll to think a better approach to avoid calling several times the same webhook
  const leftRightRef = useScrollAnimation();

  const copyCenteredRef = useScrollAnimation();

  const technologiesListRef = useScrollAnimation();

  const titleCopyFirst = useScrollAnimation();

  const titleCopySecond = useScrollAnimation();

  const imageGalleryFirst = useScrollAnimation();

  const imageGallerySecond = useScrollAnimation();

  const { elementRef: leftRightCopyFirst, titleRef: leftRightCopyFirstTitle } =
    useStickyScrollAnimation();

  const { elementRef: leftRightCopySecond, titleRef: leftRightCopySecondTitle } =
    useStickyScrollAnimation();

  const { elementRef: leftRightCopyThird, titleRef: leftRightCopyThirdTitle } =
    useStickyScrollAnimation();

  const sectionHeaderRef = useScrollAnimation();

  const fullScreenSliderRef = useScrollAnimation();

  const animatedTagCopyRef = useScrollAnimation();

  const lightSeparatorFirst = useScrollAnimation();

  const lightSeparatorSecond = useScrollAnimation();

  const lightSeparatorThird = useScrollAnimation();

  const darkSeparatorFirst = useScrollAnimation();

  const darkSeparatorSecond = useScrollAnimation();

  useEffect(() => {
    setCurrentPage('Case Study');
  }, []);

  if (!caseStudy) {
    return <NotFoundPage />;
  }

  const {
    hero: { title, heroDescriptionText, bgImg, projectSection },
    pageDescription,
    externalLink,
  } = caseStudy || {};

  return (
    <main className="page page--case-study">
      <CaseStudyBanner
        pageDescription={pageDescription}
        descriptionText={heroDescriptionText}
        title={title}
        bgImg={bgImg}
        projectSection={projectSection}
        paralaxTranslateY={[20, -20]}
        externalLink={externalLink}
      />
      <div className="case-study-content">
        <div
          id="light-section"
          ref={projectWhiteSectionRef}
          className="flex flex-col gap-36 bg-white py-36"
        >
          <TitleCopy
            ref={titleCopyFirst}
            title="The Client"
            description="White Canvas, a leader in the WordPress Web Design industry, recognized the need for an innovative QA automation tool to enhance their pixel-perfect workflow. Specializing in delivering high-quality digital experiences, the collaboration aimed to streamline their quality assurance process, ensuring flawless execution in their projects."
            className={ANIMATED_CLASSNAME}
          />
          <ImageGallery
            ref={imageGalleryFirst}
            className={cn('px-4 md:px-8 xl:px-0', ANIMATED_CLASSNAME)}
            images={[{ src: '/images/case-studies/unifine/unifine.webp', alt: 'image1' }]}
          />
          <LeftRight
            ref={leftRightRef}
            title="The Need"
            subtitle="A CUSTOM System"
            description="White Canvas faced the challenge of conducting extensive manual testing, which was both time-consuming and prone to errors. As their projects grew in complexity, the need for a more efficient and reliable QA process became critical to maintaining high-quality standards and preventing issues from reaching production."
            imageSrc="/images/case-studies/unifine/unifine2.webp"
            imageSide="right"
            className={ANIMATED_CLASSNAME}
          />
          <Separator
            ref={lightSeparatorFirst}
            className={cn('mx-auto max-w-7xl', ANIMATED_CLASSNAME)}
          />
          <CopyCentered
            ref={copyCenteredRef}
            tag="GOAL"
            copy="The primary goal was to develop a user-friendly QA automation tool that could be seamlessly integrated into the client's existing workflow."
            description="This tool needed to provide a visual interface for BackstopJS, enabling easy execution of regression tests in the cloud. The main challenge was designing an intuitive interface that allowed both QA professionals and non-technical team members to conduct visual QA tests effortlessly."
            className={ANIMATED_CLASSNAME}
          />
          <Separator
            ref={lightSeparatorSecond}
            className={cn('mx-auto max-w-7xl', ANIMATED_CLASSNAME)}
          />
          <TitleCopy
            ref={titleCopySecond}
            title="The Challenge"
            description="The project's main challenge was designing a user-friendly interface that allowed both QA professionals and non-technical team members to easily conduct visual QA tests. Additionally, we needed to develop a robust infrastructure to support multiple Chromium versions for BackstopJS tests, ensuring the tool could meet the diverse testing needs of the client and be marketable to other agencies."
            className={ANIMATED_CLASSNAME}
          />
          <ImageGallery
            ref={imageGallerySecond}
            className={cn('px-4 md:px-8 xl:px-0', ANIMATED_CLASSNAME)}
            images={[
              { src: '/images/case-studies/unifine/unifine3.webp', alt: 'image3' },
              { src: '/images/case-studies/unifine/unifine4.webp', alt: 'image4' },
            ]}
          />
          <SectionHeader
            title={'The Process'}
            ref={sectionHeaderRef}
            description="Our approach to creating the QA automation tool was structured and collaborative, ensuring alignment with the client's goals from concept to deployment. The process was divided into three key phases: LoFi Wireframes, HiFi Wireframes, and Development, each critical in turning the vision into a functional and user-friendly solution."
            className={cn('mx-auto px-4 md:px-8 xl:px-0', ANIMATED_CLASSNAME)}
          />
          <LeftRightCopy
            ref={leftRightCopyFirst}
            titleRef={leftRightCopyFirstTitle}
            contentSide="left"
            title="LoFi Wireframes"
            copy="Our process began with creating low-fidelity wireframes to visualize the basic structure and functionality of the QA automation tool. This step was crucial for aligning our vision with the client's needs, allowing us to quickly iterate on ideas and make adjustments before committing to more detailed designs. The focus was on ensuring the interface would be intuitive for both technical and non-technical users."
            images={[
              { src: '/images/case-studies/unifine/unifine5.webp', alt: 'image5' },
              { src: '/images/case-studies/unifine/unifine2.webp', alt: 'image2' },
              { src: '/images/case-studies/unifine/unifine3.webp', alt: 'image3' },
            ]}
            list={{
              tag: 'Goals',
              items: ['LoFi Wireframes', 'Feedback & Iterations', 'Clear Flow'],
            }}
          />
          <LeftRightCopy
            ref={leftRightCopySecond}
            titleRef={leftRightCopySecondTitle}
            title="HiFi Wireframes"
            copy="With the foundational concepts in place, we moved on to high-fidelity wireframes. These detailed designs brought the interface to life, incorporating visual elements and user interaction details. We worked closely with the client during this phase to refine the tool's look and feel, ensuring it met their expectations and was user-friendly. This step was vital for transitioning from concept to a concrete, actionable design that would guide development."
            images={[
              { src: '/images/case-studies/unifine/unifine6.webp', alt: 'image6' },
              { src: '/images/case-studies/unifine/unifine2.webp', alt: 'image2' },
              { src: '/images/case-studies/unifine/unifine3.webp', alt: 'image3' },
            ]}
            list={{
              tag: 'Goals',
              items: ['LoFi Wireframes', 'Feedback & Iterations', 'Clear Flow'],
            }}
          />
          <LeftRightCopy
            ref={leftRightCopyThird}
            titleRef={leftRightCopyThirdTitle}
            contentSide="left"
            title="Development"
            copy="Once the designs were finalized, we proceeded to the development phase. Our team built the tool using a robust infrastructure to support multiple Chromium versions for BackstopJS tests, ensuring it was capable of handling diverse testing scenarios. Throughout development, we maintained close collaboration with the client, providing regular updates and incorporating feedback to ensure the final product met their needs and was ready for broader market adoption."
            images={[
              { src: '/images/case-studies/unifine/unifine7.webp', alt: 'image7' },
              { src: '/images/case-studies/unifine/unifine2.webp', alt: 'image2' },
              { src: '/images/case-studies/unifine/unifine3.webp', alt: 'image3' },
            ]}
            list={{
              tag: 'Goals',
              items: ['LoFi Wireframes', 'Feedback & Iterations', 'Clear Flow'],
            }}
          />
          <Separator
            ref={lightSeparatorThird}
            className={cn('-mb-36 mx-auto max-w-7xl', ANIMATED_CLASSNAME)}
          />
        </div>
        <div className="animated-copy animate-transition flex flex-col gap-36 bg-loop-black py-36">
          <TechnologiesList
            ref={technologiesListRef}
            icons={[
              <ReactLogo key="react" />,
              <SpringLogo key="spring" />,
              <TypescriptLogo key="typescript" />,
            ]}
            description="For White Canvas's QA automation tool, we selected a tech stack that prioritizes performance, flexibility, and scalability. React provided a dynamic, user-friendly front end, while Node.js powered the backend with efficiency and speed. Leveraging AWS ensured robust, scalable cloud infrastructure that could handle the demands of extensive testing. This combination allowed us to deliver a high-performance, reliable solution that seamlessly integrates into their daily operations, supporting long-term growth and success."
            className={{
              section: ANIMATED_CLASSNAME,
              text: 'animate-transition-text text-white',
            }}
          />
          <AnimatedTagCopy
            ref={animatedTagCopyRef}
            tag="the result"
            className={{
              section: cn(ANIMATED_CLASSNAME, 'mx-auto px-4 md:px-8 xl:px-0'),
              tag: 'animate-transition-text',
            }}
            animatedCopy={{
              id: 'animated-copy-1',
              text: "<w>/<w>The <w>QA automation<w> tool has become a key part of White Canvas's daily operations, <w>saving significant time<w> on manual testing. Its intuitive design allows the team to catch and report bugs before they reach production, improving overall <w>project quality<w>. This tool has proven to be an indispensable asset, driving efficiency and enhancing outcomes.",
              trigger: '.animated-copy',
              animationStart: 'top 30%',
              className: 'mx-auto',
            }}
          />
          <FullScreenSlider
            ref={fullScreenSliderRef}
            className={cn('mx-auto px-4 md:px-8 xl:px-0', ANIMATED_CLASSNAME)}
            tag="the product"
            images={[
              { src: '/images/case-studies/unifine/unifine8.webp', alt: 'image7' },
              { src: '/images/case-studies/unifine/unifine2.webp', alt: 'image2' },
              { src: '/images/case-studies/unifine/unifine3.webp', alt: 'image3' },
            ]}
            description="New York Times Media Kit's mission is to help brands make an impact in the world. They have always been on the forefront when it comes to finding new ways to keep readers engaged."
          />
          <Separator
            ref={darkSeparatorFirst}
            className={cn('mx-auto w-full max-w-[1064px] bg-gray-dark', ANIMATED_CLASSNAME)}
          />
          <Review
            client={{ name: 'Facundo Gamond', role: 'CTO at White Canvas' }}
            message="“Loop3 was incredibly easy to work with, consistently bringing fresh ideas to the table and enhancing our project with innovative solutions.”"
            logo={<ClutchLogo />}
          />
          <Separator
            ref={darkSeparatorSecond}
            className={cn('mx-auto w-full max-w-[1064px] bg-gray-dark', ANIMATED_CLASSNAME)}
          />
        </div>
      </div>
    </main>
  );
};

export default Unifine;

export const Head = () => {
  if (caseStudy) {
    return (
      <SEO title="Loop3 Studio - Unifine" description={`${caseStudy.hero.heroDescriptionText}`} />
    );
  }
};
